import { t } from 'i18next';
import i18n from '../i18n';
import moment from 'moment';
import { IRelativeDayTime } from './interfaces';

export const formatDateForInput = (date: Date): string => {
  const pad = (n: number) => (n < 10 ? '0' + n : n);
  return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(
    date.getDate()
  )}T${pad(date.getHours())}:${pad(date.getMinutes())}`;
};

export const formatString = (
  template: string,
  variables: { [key: string]: string | number }
): string => {
  return template.replace(
    /\${(.*?)}/g,
    (_, key) => variables[key].toString() || ''
  );
};

export const formatTimeSince = (timeInMinutes: number): string => {
  const hours = Math.floor(timeInMinutes / 60);
  const remainingMinutes = Math.floor(timeInMinutes % 60);

  const variables = { timeInMinutes, hours, remainingMinutes };

  switch (true) {
    case timeInMinutes < 1:
      return t('latestFeeding.lessThenMinute', variables);
    case timeInMinutes === 1:
      return t('latestFeeding.minute', variables);
    case timeInMinutes < 60:
      return formatString(t('latestFeeding.minutes'), variables);
    case timeInMinutes === 60:
      return formatString(t('latestFeeding.hour'), variables);
    case remainingMinutes === 0 && hours > 0:
      return formatString(t('latestFeeding.hours'), variables);
    case remainingMinutes === 1 && hours === 1:
      return formatString(t('latestFeeding.hourAndMinute'), variables);
    case remainingMinutes > 1 && hours === 1:
      return formatString(t('latestFeeding.hourAndMinutes'), variables);
    case remainingMinutes === 1 && hours > 1:
      return formatString(t('latestFeeding.hoursAndMinute'), variables);
    case remainingMinutes > 1 && hours > 1:
      return formatString(t('latestFeeding.hoursAndMinutes'), variables);
  }

  return '----';
};

export const formatRelativeDayTime = (date: Date): IRelativeDayTime => {
  const relativeDay = determineDateDescription(date);
  const time = moment(date).format('HH:mm');
  return { relativeDay, time };
};

const determineDateDescription = (inputDate: Date): string => {
  const today = new Date();

  // Clear time portion for comparison
  const todayStart = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate()
  );
  const inputStart = new Date(
    inputDate.getFullYear(),
    inputDate.getMonth(),
    inputDate.getDate()
  );

  // Calculate the difference in days
  const diffInTime = todayStart.getTime() - inputStart.getTime();
  const diffInDays = Math.floor(diffInTime / (1000 * 60 * 60 * 24));

  if (diffInDays === 0) return t('relativeDay.today');
  if (diffInDays === 1) return t('relativeDay.yesterday');
  if (diffInDays === 2) return t('relativeDay.twoDays');
  if (diffInDays > 2)
    return formatString(t('relativeDay.days'), { days: diffInDays });

  return 'In the future'; // For dates after today
};
