import * as React from 'react';
import { SVGProps } from 'react';
const DarkModeMoon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    xmlSpace='preserve'
    width='1em'
    height='1em'
    viewBox='0 0 504.147 504.147'
    {...props}
  >
    <path
      d='M397.017 370.594c-145.51 0-263.459-117.941-263.459-263.459 0-38.156 8.184-74.382 22.788-107.126C64.232 41.07.012 133.364.012 240.703c0 145.495 117.965 263.444 263.452 263.444 107.339 0 199.625-64.228 240.672-156.325-32.73 14.587-68.964 22.772-107.119 22.772z'
      style={{
        fill: '#8c8883',
      }}
    />
    <path
      d='M305.471 435.877c77.296 0 146.684-33.422 194.851-86.473-31.736 13.556-66.615 21.181-103.306 21.181-145.51 0-263.459-117.941-263.459-263.459 0-38.156 8.184-74.382 22.788-107.126-36.328 16.211-68.206 40.464-93.633 70.38-13.233 31.397-20.693 65.827-20.693 102.053 0 145.488 117.957 263.444 263.452 263.444z'
      style={{
        fill: '#7c7b7a',
      }}
    />
    <circle
      cx={45.115}
      cy={336.754}
      r={5.908}
      style={{
        fill: '#99948f',
      }}
    />
    <circle
      cx={77.284}
      cy={374.17}
      r={7.877}
      style={{
        fill: '#99948f',
      }}
    />
    <circle
      cx={41.83}
      cy={256.016}
      r={6.561}
      style={{
        fill: '#99948f',
      }}
    />
    <circle
      cx={100.246}
      cy={264.523}
      r={16.408}
      style={{
        fill: '#99948f',
      }}
    />
    <circle
      cx={195.438}
      cy={418.808}
      r={3.938}
      style={{
        fill: '#99948f',
      }}
    />
    <circle
      cx={237.446}
      cy={385.985}
      r={6.561}
      style={{
        fill: '#99948f',
      }}
    />
    <circle
      cx={364.784}
      cy={460.816}
      r={6.561}
      style={{
        fill: '#99948f',
      }}
    />
    <circle
      cx={351.008}
      cy={414.184}
      r={15.1}
      style={{
        fill: '#99948f',
      }}
    />
    <ellipse
      cx={308}
      cy={114.546}
      rx={6.561}
      ry={62.031}
      style={{
        fill: '#d6c77c',
      }}
    />
    <ellipse
      cx={308.021}
      cy={114.551}
      rx={62.03}
      ry={6.554}
      style={{
        fill: '#d6c77c',
      }}
      transform='rotate(-45.025 308.022 114.555)'
    />
    <ellipse
      cx={308}
      cy={114.546}
      rx={62.023}
      ry={6.561}
      style={{
        fill: '#d6c77c',
      }}
    />
    <ellipse
      cx={307.979}
      cy={114.58}
      rx={62.03}
      ry={6.561}
      style={{
        fill: '#d6c77c',
      }}
      transform='rotate(45.025 307.982 114.577)'
    />
    <ellipse
      cx={311.938}
      cy={279.962}
      rx={2.19}
      ry={20.677}
      style={{
        fill: '#99948f',
      }}
    />
    <ellipse
      cx={311.933}
      cy={279.969}
      rx={20.677}
      ry={2.182}
      style={{
        fill: '#99948f',
      }}
      transform='rotate(-45.073 311.931 279.974)'
    />
    <ellipse
      cx={311.938}
      cy={279.962}
      rx={20.669}
      ry={2.19}
      style={{
        fill: '#99948f',
      }}
    />
    <ellipse
      cx={311.949}
      cy={279.968}
      rx={20.677}
      ry={2.19}
      style={{
        fill: '#99948f',
      }}
      transform='rotate(45.001 311.954 279.966)'
    />
  </svg>
);
export default DarkModeMoon;
