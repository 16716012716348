import React, { useEffect, useState } from 'react';
import FeedingForm from './components/FeedingForm';
import FeedingList from './components/FeedingList';
import styles from './styles/App.module.css';
import { useTranslation } from 'react-i18next';
import LanguageSelector from './components/LanguageSelector';
import ThemeSelector from './components/ThemeSelector';
import './App.css';

const App: React.FC = () => {
  const { i18n } = useTranslation();

  useEffect(() => {
    // Update the direction based on the current language
    const direction = i18n.language === 'he' ? 'rtl' : 'ltr';
    document.documentElement.dir = direction;
    document.documentElement.lang = i18n.language;
  }, [i18n.language]);

  return (
    <div className={styles.container}>
      <div className={styles.settingsContainer}>
        <LanguageSelector />
        <ThemeSelector />
      </div>

      <div>
        <h1 className={styles.title}>Baby Monitor</h1>
        <FeedingForm onCreate={() => window.location.reload()} />
        <FeedingList />
      </div>
    </div>
  );
};

export default App;
