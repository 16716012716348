import React, { useEffect, useState } from 'react';
import { getFeedings, deleteFeeding, updateFeeding } from '../api/feedingApi';
import { Feeding } from '../types/Feeding';
import FeedingItem from './FeedingItem';
import FeedingModal from './FeedingModal';
import styles from '../styles/FeedingList.module.css';
import FeedingSummary from './FeedingSummary';

const FeedingList: React.FC = () => {
  const [feedings, setFeedings] = useState<Feeding[]>([]);

  const [selectedFeeding, setSelectedFeeding] = useState<Feeding | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const fetchLast24HoursFeedings = async () => {
    const now = new Date();
    const last24Hours = new Date(
      now.getTime() - 24 * 60 * 60 * 1000
    ).toISOString(); // ISO format
    const data = await getFeedings(last24Hours);
    setFeedings(data);
  };

  const handleDelete = async (id: string) => {
    await deleteFeeding(id);
    setIsModalOpen(false);
    fetchLast24HoursFeedings();
  };

  const handleUpdate = async (updatedFeeding: Feeding) => {
    if (!updatedFeeding._id) {
      return;
    }
    await updateFeeding(updatedFeeding._id, updatedFeeding);
    setIsModalOpen(false);
    fetchLast24HoursFeedings();
  };

  useEffect(() => {
    fetchLast24HoursFeedings();
  }, []);

  const handleItemClick = (feeding: Feeding) => {
    setSelectedFeeding(feeding);
    setIsModalOpen(true);
  };

  if (!feedings) {
    return <>No feedings</>;
  }

  return (
    <div className={styles.container}>
      <FeedingSummary feedings={feedings} />
      <div className={styles.list}>
        {feedings.map((feeding) => (
          <FeedingItem
            key={feeding._id}
            feeding={feeding}
            onClick={() => handleItemClick(feeding)}
          />
        ))}
      </div>
      {isModalOpen && selectedFeeding && (
        <FeedingModal
          feeding={selectedFeeding}
          onClose={() => setIsModalOpen(false)}
          onSave={handleUpdate}
          onDelete={handleDelete}
        />
      )}
    </div>
  );
};

export default FeedingList;
