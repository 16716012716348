import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import IsraelFlag from './flags/IsraelFlag';
import UsaFlag from './flags/UsaFlag';

const LanguageSelector: React.FC = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
    localStorage.setItem('language', lng);
  };

  useEffect(() => {
    const savedLanguage = localStorage.getItem('language') || 'en';
    i18n.changeLanguage(savedLanguage);
  }, [i18n]);

  return (
    <div>
      {i18n.language == 'en' ? (
        <span onClick={() => changeLanguage('he')}>
          <IsraelFlag fontSize={25} />
        </span>
      ) : (
        <span onClick={() => changeLanguage('en')}>
          <UsaFlag fontSize={25} />
        </span>
      )}
    </div>
  );
};

export default LanguageSelector;
