import * as React from 'react';
import { SVGProps } from 'react';
const LightModeSun = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    xmlSpace='preserve'
    width='1em'
    height='1em'
    viewBox='0 0 512 512'
    {...props}
  >
    <path
      d='M50.087 272.696H16.696C7.473 272.696 0 265.223 0 256s7.473-16.696 16.696-16.696h33.391c9.223 0 16.696 7.473 16.696 16.696s-7.473 16.696-16.696 16.696z'
      style={{
        fill: '#ffe14d',
      }}
    />
    <path
      d='M495.304 272.696h-33.391c-9.223 0-16.696-7.473-16.696-16.696s7.473-16.696 16.696-16.696h33.391c9.217 0 16.696 7.473 16.696 16.696s-7.479 16.696-16.696 16.696z'
      style={{
        fill: '#fc3',
      }}
    />
    <path
      d='M48.771 392.353c-5.772 0-11.381-2.994-14.473-8.353-4.615-7.984-1.875-18.196 6.108-22.804L69.325 344.5c7.995-4.615 18.202-1.88 22.804 6.108 4.615 7.984 1.875 18.196-6.108 22.804l-28.918 16.696a16.59 16.59 0 0 1-8.332 2.245z'
      style={{
        fill: '#ffe14d',
      }}
    />
    <path
      d='M434.342 169.745c-5.772 0-11.381-2.994-14.473-8.353-4.615-7.984-1.875-18.196 6.108-22.804l28.918-16.696c8.001-4.619 18.202-1.87 22.804 6.108 4.615 7.984 1.875 18.196-6.108 22.804L442.673 167.5a16.594 16.594 0 0 1-8.331 2.245z'
      style={{
        fill: '#fc3',
      }}
    />
    <path
      d='M136.331 479.945c-2.832 0-5.701-.722-8.331-2.245-7.984-4.609-10.723-14.82-6.108-22.804l16.696-28.918c4.609-7.973 14.826-10.729 22.804-6.108 7.984 4.609 10.723 14.82 6.108 22.804l-16.696 28.918c-3.092 5.354-8.701 8.353-14.473 8.353z'
      style={{
        fill: '#ffe14d',
      }}
    />
    <path
      d='M358.94 94.375c-2.832 0-5.701-.722-8.331-2.245-7.984-4.609-10.723-14.82-6.108-22.804l16.696-28.918C365.811 32.435 376.022 29.69 384 34.3c7.984 4.609 10.723 14.82 6.108 22.804l-16.696 28.918c-3.091 5.353-8.7 8.353-14.472 8.353zM375.669 479.945c-5.772 0-11.381-2.994-14.473-8.353L344.5 442.674c-4.615-7.984-1.875-18.196 6.108-22.804 8.011-4.619 18.202-1.88 22.804 6.108l16.696 28.918c4.615 7.984 1.875 18.196-6.108 22.804a16.576 16.576 0 0 1-8.331 2.245z'
      style={{
        fill: '#fc3',
      }}
    />
    <path
      d='M153.06 94.375c-5.772 0-11.381-2.994-14.473-8.353l-16.696-28.918C117.277 49.12 120.016 38.908 128 34.3c8.005-4.609 18.202-1.875 22.804 6.108L167.5 69.326c4.615 7.984 1.875 18.196-6.108 22.804a16.58 16.58 0 0 1-8.332 2.245z'
      style={{
        fill: '#ffe14d',
      }}
    />
    <path
      d='M463.229 392.353c-2.832 0-5.701-.722-8.331-2.245l-28.918-16.696c-7.984-4.609-10.723-14.82-6.108-22.804 4.609-7.973 14.82-10.723 22.804-6.108l28.918 16.696c7.984 4.609 10.723 14.82 6.108 22.804a16.694 16.694 0 0 1-14.473 8.353z'
      style={{
        fill: '#fc3',
      }}
    />
    <path
      d='M77.658 169.745c-2.832 0-5.701-.722-8.331-2.245l-28.918-16.696c-7.984-4.609-10.723-14.82-6.108-22.804 4.615-7.978 14.816-10.729 22.804-6.108l28.918 16.696c7.984 4.609 10.723 14.82 6.108 22.804a16.695 16.695 0 0 1-14.473 8.353z'
      style={{
        fill: '#ffe14d',
      }}
    />
    <path
      d='M256 411.826c-85.924 0-155.826-69.902-155.826-155.826S170.076 100.174 256 100.174 411.826 170.076 411.826 256 341.924 411.826 256 411.826z'
      style={{
        fill: '#ffc033',
      }}
    />
    <path
      d='M411.826 256c0-85.924-69.902-155.826-155.826-155.826v311.652c85.924 0 155.826-69.902 155.826-155.826z'
      style={{
        fill: '#f9a926',
      }}
    />
    <path
      d='M256 66.783c-9.223 0-16.696-7.473-16.696-16.696V16.696C239.304 7.473 246.777 0 256 0s16.696 7.473 16.696 16.696v33.391c0 9.223-7.473 16.696-16.696 16.696z'
      style={{
        fill: '#ffe14d',
      }}
    />
    <path
      d='M272.696 50.087V16.696C272.696 7.473 265.223 0 256 0v66.783c9.223 0 16.696-7.473 16.696-16.696z'
      style={{
        fill: '#fc3',
      }}
    />
    <path
      d='M256 512c-9.223 0-16.696-7.473-16.696-16.696v-33.391c0-9.223 7.473-16.696 16.696-16.696s16.696 7.473 16.696 16.696v33.391c0 9.223-7.473 16.696-16.696 16.696z'
      style={{
        fill: '#ffe14d',
      }}
    />
    <path
      d='M272.696 495.304v-33.391c0-9.223-7.473-16.696-16.696-16.696V512c9.223 0 16.696-7.473 16.696-16.696z'
      style={{
        fill: '#fc3',
      }}
    />
  </svg>
);
export default LightModeSun;
