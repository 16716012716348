import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

const resources = {
  en: {
    translation: {
      momentDateTimeFormat: 'YYYY-MM-DD HH:mm',
      totalFeedings: 'Total Feedings',

      feedingSummaryTitle: 'Feeding Summary',
      feedingSummarySubTitle: 'Last 24 hours',
      avgPerHour: 'Average Per Hour',
      quantity: 'Quantity',
      feedingStart: 'Start Time',
      latestFeeding: {
        title: 'Latest Feeding',
        lessThenMinute: 'less than a minute ago',
        minute: '1 minute ago',
        minutes: '${remainingMinutes} minutes ago',
        hour: '1 hour ago',
        hours: '${hours} hours ago',
        hourAndMinute: '1 hour and 1 minute ago',
        hourAndMinutes: '1 hour and ${remainingMinutes} minutes ago',
        hoursAndMinute: '${hours} hour and 1 minute ago',
        hoursAndMinutes: '${hours} hour and ${remainingMinutes} minutes ago',
      },
      feedingUnits: 'ml',
      relativeDay: {
        today: 'Today',
        yesterday: 'Yesterday',
        twoDays: '2 days ago',
        days: '${days} days ago',
      },
      feedingDescription:
        '<strong>${relativeDay}</strong> at <strong>${time}</strong> ate <strong>${quantity}</strong> ml',
      formActions: {
        cancel: 'Cancel',
        delete: 'Delete',
        add: 'Add',
        update: 'Update',
        reset: 'Reset',
      },
    },
  },
  he: {
    translation: {
      momentDateTimeFormat: 'DD/MM/YYYY HH:mm',
      totalFeedings: 'סך הכל האכלות',

      feedingSummaryTitle: 'סיכום האכלות',
      feedingSummarySubTitle: '24 שעות אחרונות',
      avgPerHour: 'ממוצע לשעה',
      quantity: 'כמות',
      feedingStart: 'זמן התחלה',
      latestFeeding: {
        title: 'האכלה אחרונה',
        lessThenMinute: 'לפני פחות מדקה',
        minute: 'לפני דקה אחת',
        minutes: 'לפני ${remainingMinutes} דקות',
        hour: 'לפני שעה',
        hours: 'לפני ${hours} שעות',
        hourAndMinute: 'לפני שעה ודקה אחת',
        hourAndMinutes: 'לפני שעה ו-${remainingMinutes} דקות',
        hoursAndMinute: 'לפני ${hours} שעות ודקה אחת',
        hoursAndMinutes: 'לפני ${hours} שעות ו-${remainingMinutes} דקות',
      },
      feedingUnits: 'מ"ל',
      relativeDay: {
        today: 'היום',
        yesterday: 'אתמול',
        twoDays: 'לפני יומיים',
        days: 'לפני ${days} ימים',
      },
      feedingDescription:
        '<strong>${relativeDay}</strong> ב- <strong>${time}</strong> אכל <strong>${quantity}</strong> מ"ל',
      formActions: {
        cancel: 'ביטול',
        delete: 'מחיקה',
        add: 'הוספה',
        update: 'עדכון',
        reset: 'איפוס',
      },
    },
  },
};

i18n
  .use(LanguageDetector) // Detects user's language
  .use(initReactI18next) // Initializes React bindings
  .init({
    resources,
    fallbackLng: 'en', // Default language
    interpolation: {
      escapeValue: false, // React already escapes by default
    },
  });

export default i18n;
