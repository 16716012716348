import React from 'react';
import { Styles } from '../../common/interfaces';

export interface IFormInputProps {
  label?: string;
  value?: string | readonly string[] | number | undefined;
  onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined;
  onFocus?: React.FocusEventHandler<HTMLInputElement> | undefined;
  type?: React.HTMLInputTypeAttribute | undefined;
  required?: boolean | undefined;
  dir?: string | undefined;
  name?: string | undefined;
  selectOnFocus?: boolean | undefined;
}

const FormInput: React.FC<IFormInputProps> = ({
  value,
  onChange,
  onFocus,
  label,
  type,
  required,
  dir,
  name,
  selectOnFocus,
}) => {
  const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    if (selectOnFocus) {
      event.target.select();
    }

    if (onFocus) {
      onFocus(event);
    }
  };

  return (
    <div style={styles.container}>
      {label && <label style={styles.label}>{label}</label>}

      <input
        dir={dir}
        name={name}
        style={styles.input}
        type={type}
        value={value}
        onChange={onChange}
        onFocus={handleFocus}
        required={required}
      />
    </div>
  );
};

const styles: Styles = {
  container: { display: 'flex', flexDirection: 'column' },
  input: {
    padding: 8,
    fontSize: 14,
    borderRadius: 5,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: 'var(--border-color)',
    backgroundColor: 'var(--bg-color)',
    color: 'var(--text-primary)',
  },
  label: {
    marginBottom: 5,
  },
};

export default FormInput;
