import React, { useState } from 'react';
import styles from '../styles/FeedingModal.module.css';
import { Feeding } from '../types/Feeding';
import { formatDateForInput } from '../common/utils';
import { t } from 'i18next';
import FormInput from './form/FormInput';

interface FeedingModalProps {
  feeding: Feeding;
  onClose: () => void;
  onSave: (updatedFeeding: Feeding) => void;
  onDelete: (feedingId: string) => void;
}

const FeedingModal: React.FC<FeedingModalProps> = ({
  feeding,
  onClose,
  onSave,
  onDelete,
}) => {
  const [quantity, setQuantity] = useState(feeding.quantity);
  const [start, setStart] = useState(
    formatDateForInput(new Date(feeding.start))
  );
  console.log(start);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSave({ ...feeding, quantity, start: start });
  };

  const handleDelete = (e: React.FormEvent) => {
    e.preventDefault();
    onDelete(feeding._id || '');
  };

  return (
    <div className={styles.overlay}>
      <div className={styles.modal}>
        <h3>{t('formActions.update')}</h3>
        <form onSubmit={handleSubmit} className={styles.form}>
          <FormInput
            label={t('quantity')}
            type='number'
            value={quantity}
            onChange={(e) => setQuantity(Number(e.target.value))}
            required
            selectOnFocus={true}
          />
          <FormInput
            label={t('feedingStart')}
            type='datetime-local'
            value={start}
            onChange={(e) => setStart(e.target.value)}
            required
          />

          <div className={styles.actions}>
            <button type='submit'>{t('formActions.update')}</button>
            <button
              type='button'
              className={styles.deleteBtn}
              onClick={handleDelete}
            >
              {t('formActions.delete')}
            </button>
            <button
              type='button'
              className={styles.cancelBtn}
              onClick={onClose}
            >
              {t('formActions.cancel')}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default FeedingModal;
