import React from 'react';
import styles from '../styles/FeedingItem.module.css';
import { Feeding } from '../types/Feeding';
import moment from 'moment';
import { t } from 'i18next';
import { formatRelativeDayTime, formatString } from '../common/utils';

interface FeedingItemProps {
  feeding: Feeding;
  onClick: () => void;
}

const FeedingItem: React.FC<FeedingItemProps> = ({ feeding, onClick }) => {
  const relativeDayTime = formatRelativeDayTime(new Date(feeding.start));
  const variables = { ...relativeDayTime, quantity: feeding.quantity };
  const htmlContent = formatString(t('feedingDescription'), variables);
  return (
    <div className={styles.card} onClick={onClick}>
      <div className={styles.details}>
        <p dangerouslySetInnerHTML={{ __html: htmlContent }}></p>
      </div>
    </div>
  );
};

export default FeedingItem;
