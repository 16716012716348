import React, { useEffect, useState } from 'react';
import { Feeding } from '../types/Feeding';

import styles from '../styles/FeedingList.module.css';
import { useTranslation } from 'react-i18next';
import { formatTimeSince } from '../common/utils';

interface IFeedingSummary {
  totalFeedings: number;
  feedingPerHour: number;
  timeSinceLastFeeding: number;
}

interface IFeedingSummaryProps {
  feedings: Feeding[];
}

const FeedingSummary: React.FC<IFeedingSummaryProps> = ({ feedings }) => {
  const [feedingSummary, setFeedingSummary] = useState<IFeedingSummary>({
    totalFeedings: 0,
    feedingPerHour: 0,
    timeSinceLastFeeding: 0,
  });

  const { t } = useTranslation();

  useEffect(() => {
    const calculateFeedingSummary = () => {
      if (feedings.length === 0) {
        return {
          totalFeedings: 0,
          feedingPerHour: 0,
          timeSinceLastFeeding: 0,
        };
      }

      // Total number of feedings
      const totalFeedings = feedings.length;

      // Get the earliest and latest feeding start times
      const startTimes = feedings.map((feeding) =>
        new Date(feeding.start).getTime()
      );
      const earliestStart = Math.min(...startTimes);
      //const latestStart = Math.max(...startTimes);
      const latestStart = new Date().getTime();

      // Total elapsed time in hours
      const elapsedTimeInHours =
        (latestStart - earliestStart) / (1000 * 60 * 60);

      // Total feeding quantity
      const totalQuantity = feedings.reduce(
        (sum, feeding) => sum + feeding.quantity,
        0
      );

      console.log(elapsedTimeInHours);

      // Feeding per hour
      const feedingPerHour =
        elapsedTimeInHours > 0
          ? totalQuantity / elapsedTimeInHours
          : totalQuantity;

      // Time since the last feeding
      const now = new Date();
      const lastFeedingEnd = feedings
        .map((feeding) => new Date(feeding.start).getTime())
        .sort((a, b) => b - a)[0];
      const timeSinceLastFeeding =
        (now.getTime() - lastFeedingEnd) / (1000 * 60);

      return {
        totalFeedings,
        feedingPerHour,
        timeSinceLastFeeding,
      };
    };

    setFeedingSummary(calculateFeedingSummary());
  }, [feedings]);

  return (
    <div
      style={{
        backgroundColor: 'var(--secondary-bg-color)',
        color: 'var(--text-primary)',
        padding: 10,
        borderRadius: 10,
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        marginBottom: 15,
      }}
    >
      <h2 className={styles.title}>{t('feedingSummaryTitle')}</h2>
      <h3 className={styles.subTitle}>{t('feedingSummarySubTitle')}</h3>
      <div>
        <p>
          <strong>{t('totalFeedings')}: </strong>
          {feedingSummary.totalFeedings}
        </p>
        <p>
          <strong>{t('avgPerHour')}: </strong>
          {feedingSummary.feedingPerHour.toFixed(2)} {t('feedingUnits')}
        </p>
        <p>
          <strong>{t('latestFeeding.title')}: </strong>
          {formatTimeSince(feedingSummary.timeSinceLastFeeding)}
        </p>
      </div>
    </div>
  );
};

export default FeedingSummary;
