import React, { useEffect, useState } from 'react';
import { useTheme } from '../contexts/ThemeContext';
import DarkModeMoon from './icons/DarkModeMoon';
import LightModeSun from './icons/LightModeSun';

const ThemeSelector = () => {
  const { theme, toggleTheme } = useTheme();

  return (
    <div>
      <span onClick={toggleTheme}>
        {theme === 'light' ? (
          <DarkModeMoon fontSize={25} />
        ) : (
          <LightModeSun fontSize={25} />
        )}
      </span>
    </div>
  );
};

export default ThemeSelector;
