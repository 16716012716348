import React, { useState } from 'react';
import styles from '../styles/FeedingForm.module.css';
import { createFeeding } from '../api/feedingApi';
import { Feeding } from '../types/Feeding';
import { formatDateForInput } from '../common/utils';
import { t } from 'i18next';
import FormInput from './form/FormInput';

interface FeedingFormProps {
  onCreate: () => void;
}

const FeedingForm: React.FC<FeedingFormProps> = ({ onCreate }) => {
  const getLastQuantity = (): number => {
    const savedLastQuantity = localStorage.getItem('lastQuantity');
    return savedLastQuantity ? parseInt(savedLastQuantity) : 120;
  };
  const [form, setForm] = useState<Feeding>({
    start: formatDateForInput(new Date()),
    //end: '',
    quantity: getLastQuantity(),
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setForm((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    await createFeeding(form);
    onCreate();
    setForm({ start: '', end: '', quantity: 0 });
  };

  return (
    <form className={styles.form} onSubmit={handleSubmit}>
      <FormInput
        dir='ltr'
        type='datetime-local'
        name='start'
        value={form.start}
        onChange={handleChange}
        required
      />
      <FormInput
        dir='ltr'
        type='number'
        name='quantity'
        value={form.quantity}
        onChange={handleChange}
        selectOnFocus={true}
        required
      />
      <button className={styles.button} type='submit'>
        {t('formActions.add')}
      </button>
    </form>
  );
};

export default FeedingForm;
