import axios from 'axios';
import { Feeding } from '../types/Feeding';

const backendHost = process.env.REACT_APP_BACKEND_HOST || '34.165.67.188';
const backendPort = process.env.REACT_APP_BACKEND_PORT || '4040';

console.log(process.env);
const BASE_URL = `http://${backendHost}:${backendPort}/api/feedings`;
console.log({ BASE_URL });

export const getFeedings = async (startAfter?: string): Promise<Feeding[]> => {
  const params = startAfter ? { startAfter } : {};
  const response = await axios.get(BASE_URL, { params });
  return response.data;
};

export const getFeedingById = async (id: string): Promise<Feeding> => {
  const response = await axios.get(`${BASE_URL}/${id}`);
  return response.data;
};

export const createFeeding = async (data: Feeding): Promise<Feeding> => {
  console.log('sdf');
  const response = await axios.post(BASE_URL, data);
  return response.data;
};

export const updateFeeding = async (
  id: string,
  data: Feeding
): Promise<Feeding> => {
  const response = await axios.put(`${BASE_URL}/${id}`, data);
  return response.data;
};

export const deleteFeeding = async (id: string): Promise<void> => {
  await axios.delete(`${BASE_URL}/${id}`);
};
